import React, { useState } from "react";
import Camera from "./../Images/Camera.svg";
import PrimaryCTA from "./PrimaryCTA";
import { CircularProgress } from "@mui/material";

export default function UploadPhoto({ step, handleImageUpload, displayImage }) {
  const Upload = () => {
    return (
      <div className="upload-photo">
        <img src={Camera} alt="" />
        <label htmlFor="contained-button-file">
          <input
            style={{ display: "none" }}
            accept="image/*"
            id="contained-button-file"
            multiple
            onChange={(e) => handleImageUpload(e)}
            type="file"
          />
          <PrimaryCTA component="span" text="Upload Photo" />
        </label>
      </div>
    );
  };

  const LoadingState = () => {
    return (
      <div className="upload-photo-loader">
        <CircularProgress />
      </div>
    );
  };
  const ImageView = () => {
    return (
      <div className="upload-photo-img">
        <img src={displayImage} alt="" />
      </div>
    );
  };

  return (
    <div className="upload-photo-container">
      {step === 0 && <Upload />}
      {step === 1 && <LoadingState />}
      {step === 2 && <ImageView />}
    </div>
  );
}
