import React, { useState } from "react";
import Header from "../../components/Header";
import PrimaryCTA from "../../components/PrimaryCTA";
import ThankYouLogo from "../../Images/Thank-you.svg";
import { useDispatch, useSelector } from "react-redux";
import { resetUserReducer } from "../../Reducer/userReducer";
import { useHistory } from "react-router-dom";

export default function ThankYou() {
  const dispatch = useDispatch();
  const history = useHistory();
  // const state = useSelector((state) => state);
  // console.log("state", state);

  // const handleContinue = () => {
  //   dispatch(resetUserReducer());
  //   history.push("/");
  // };
  return (
    <div className="thank-you-screen">
      <div className="box">
        <div className="container">
          <img src={ThankYouLogo} alt="" />
          <h1>Thank you</h1>
          <p>
            Your document have been submitted <br /> successfully.
          </p>
          <p>You will be updated in under 24 hrs</p>
          {/* <div className="cta-container">
            <PrimaryCTA onClick={handleContinue} text="continue" />
          </div> */}
        </div>
      </div>
    </div>
  );
}
