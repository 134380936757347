import React, { useState } from "react";
import Header from "../../components/Header";
import PrimaryCTA from "../../components/PrimaryCTA";
import ProgressBar from "../../components/ProgressBar";
import UploadPhoto from "../../components/UploadPhoto";
import { useHistory } from "react-router";
import { uplaodImage } from "../../Actions/User";
import { useDispatch } from "react-redux";
import { setAadharImgFront } from "../../Reducer/userReducer";
import { uploadDocument } from "../../Actions/User";

export default function AadharUpload() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [response, setResponse] = useState("");
  const [step, setStep] = useState(0);
  const [loadingCTA, setLoadingCTA] = useState(false);

  const gotoNextPage = () => {
    history.push("/aadhar-upload-back");
  };

  const handleSubmit = () => {
    // dispatch(setAadharImgFront(response));
    setLoadingCTA(true);
    let data = {
      aadhar_card_link: response,
    };
    uploadDocument(data, gotoNextPage);
  };

  const handleImageUpload = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      setStep(1);
      const formData = new FormData();
      formData.append("file", files[0]);
      uplaodImage(formData, setResponse, setStep);
    }
  };
  return (
    <div className="aadhar-upload-screen">
      <Header />
      <div className="box">
        <ProgressBar value={50} />
        <div className="container">
          <p>
            Upload AADHAAR front side as show blow: <br />
            <span> (.png, .jpg, .pdf files are acceptable)</span>
          </p>
          <UploadPhoto
            step={step}
            handleImageUpload={handleImageUpload}
            displayImage={response}
          />
          <div className="cta-container">
            {step === 2 && (
              <PrimaryCTA
                loading={loadingCTA}
                disabled={loadingCTA}
                text="Next"
                onClick={() => handleSubmit()}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
