import axios from "axios";
import { getTokenfromUrl } from "../Config/Config";

const baseURL = "https://api.fastor.ai";

export const uplaodImage = (formData, setResponse, setStep) => {
  formData.append("upload_preset", "ypqeu3mz");
  formData.append("api_key", 324683829857419);
  formData.append("timestamp", Math.round(new Date().getTime() / 1000));
  formData.append("folder", "signed_upload_demo_form");

  const url = "https://api.cloudinary.com/v1_1/myedugatetech/image/upload";
  axios
    .post(url, formData)
    .then((response) => {
      setResponse(response?.data);
      console.log("image Upload", response);
      if (response?.status === 200) {
        setResponse(response?.data?.secure_url);
        setStep(2);
      }
    })
    .catch((error) => console.log("Error", error));
};

//

export const getDocument = (history, setCtaLoading) => {
  let url = `${baseURL}/v2/webkyc/document`;

  let token = getTokenfromUrl();
  if (localStorage?.getItem("token")) {
    token = localStorage.getItem("token");
  } else token = getTokenfromUrl();

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  axios
    .get(url, config)
    .then((res) => {
      console.log("RES", res);
      if (res?.data?.status_code === 200) {
        const { data } = res?.data;
        console.log("DATA", data);
        if (data?.user_selfie) {
          if (
            data?.business_type &&
            data?.business_legal_name &&
            data?.pan_card_number
          ) {
            console.log("BD");
            if (data?.pan_card_link) {
              console.log("BD UPLOAD");
              if (data?.aadhar_number && data?.owner_name) {
                console.log("ADDHAR");
                if (data?.aadhar_card_link) {
                  console.log("AADHAR  Front");
                  if (data?.aadhar_card_back_link) {
                    console.log("aadhar back");

                    if (data?.account_holder_name) {
                      if (data?.gst_number) {
                        history.push("/thank-you");
                      } else {
                        history.push("/thank-you");
                        // history.push("/gst");
                      }
                    } else {
                      history.push("/bank");
                    }
                  } else {
                    history.push("/aadhar-upload-back");
                  }
                } else {
                  history.push("/aadhar-upload");
                }
              } else {
                history.push("/aadhar");
              }
            } else {
              history.push("/business-details-upload");
            }
          } else {
            history.push("/business-details");
          }
        } else {
          // history.push("/selfie");
          history.push("/");
        }
      } else {
        localStorage.removeItem("token");
        history.push("/404");
      }
      setCtaLoading(false);
    })
    .catch((e) => {
      console.log(e);
      history.push("/404");
    });
};

export const uploadDocument = (data, gotoNextPage, setToast) => {
  let url = `${baseURL}/v2/webkyc/document`;
  let token = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  axios
    .patch(url, data, config)
    .then((res) => {
      console.log("Uplod doc", res);
      if (res?.data?.status_code === 200) {
        gotoNextPage();
      }
      if (res?.data?.status_code === 402) {
        setToast({
          open: true,
          isSuccess: false,
          msg: res?.data?.error_message,
        });
      }
    })
    .catch((e) => {
      console.log(e);
      window.location.url = "/404";
    });
};
