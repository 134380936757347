import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import ProgressBar from "../../components/ProgressBar";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import PrimaryCTA from "../../components/PrimaryCTA";
import { setBusiness } from "../../Reducer/userReducer";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { uploadDocument } from "../../Actions/User";
import Toast from "../../components/Toast";

export default function Bank() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [toast, setToast] = useState({
    open: false,
    isSuccess: false,
    msg: "",
  });
  const [type, setType] = useState("bank");
  const [accountNumber, setAccountNumber] = useState("");
  const [name, setName] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [upi, setUpi] = useState("");

  const [loadingCTA, setLoadingCTA] = useState(false);

  const gotoNextPage = () => {
    history.push("/gst");
  };

  useEffect(() => {
    if (toast?.open === false) {
      setLoadingCTA(false);
    }
  }, [toast]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingCTA(true);

    let data;
    if (type === "bank") {
      data = {
        payment_method: "bank",
        account_number: accountNumber,
        ifsc_code: ifsc,
        account_holder_name: name,
      };
    } else {
      data = {
        payment_method: "upi",
        upi_id: upi,
        account_holder_name: name,
      };
    }

    uploadDocument(data, gotoNextPage, setToast);
  };

  const handleChange = (event) => {
    setType(event.target.value);
  };
  return (
    <div className="bank-details-screen">
      <Header />
      <div className="box">
        <ProgressBar value={80} />
        <div className="container">
          <p>Document: Bank</p>

          <form onSubmit={(e) => handleSubmit(e)}>
            <FormControl
              className="form"
              variant="standard"
              sx={{ m: 0, width: "100%" }}
            >
              <InputLabel id="demo-simple-select-standard-label">
                Select Type
              </InputLabel>
              <Select
                required
                value={type}
                onChange={handleChange}
                disableUnderline
              >
                <MenuItem value={"bank"}>Bank Account</MenuItem>
                <MenuItem value={"upi"}>UPI ID</MenuItem>
              </Select>

              {type === "bank" ? (
                <>
                  <h1>Enter Account Number </h1>
                  <TextField
                    value={accountNumber}
                    onChange={(e) => setAccountNumber(e.target.value)}
                    variant="standard"
                    placeholder="AAAA00034"
                    required
                  />

                  <h1>Enter IFSC Code </h1>
                  <TextField
                    required
                    value={ifsc}
                    onChange={(e) => setIfsc(e.target.value)}
                    variant="standard"
                    placeholder="Albert Flores"
                  />
                </>
              ) : (
                <>
                  <h1>Enter UPI ID </h1>
                  <TextField
                    value={upi}
                    onChange={(e) => setUpi(e.target.value)}
                    variant="standard"
                    placeholder="AAAA00034"
                    required
                  />
                </>
              )}

              <h1>Enter Accout Holder Name </h1>
              <TextField
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                variant="standard"
                placeholder="Albert Flores"
              />

              <div className="cta-container">
                <PrimaryCTA
                  loading={loadingCTA}
                  disabled={loadingCTA}
                  type="submit"
                  text="Next"
                />
              </div>
            </FormControl>
          </form>
        </div>
      </div>
      <Toast
        data={toast}
        handleClose={() =>
          setToast({
            open: false,
            isSuccess: false,
            msg: "",
          })
        }
      />
    </div>
  );
}
