import React, { useState } from "react";
import Header from "../../components/Header";
import ProgressBar from "../../components/ProgressBar";
import TextField from "@mui/material/TextField";
import PrimaryCTA from "./../../components/PrimaryCTA";
import { useDispatch, useSelector } from "react-redux";
import { setAadhar } from "../../Reducer/userReducer";
import { useHistory } from "react-router";
import { uploadDocument } from "../../Actions/User";
import Toast from "../../components/Toast";

export default function Aadhar() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");
  const [loadingCTA, setLoadingCTA] = useState(false);

  const [toast, setToast] = useState({
    open: false,
    isSuccess: false,
    msg: "",
  });

  const gotoNextPage = () => {
    history.push("/aadhar-upload");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // dispatch(
    //   setAadhar({
    //     aadharNumber: aadharNumber,
    //     name: name,
    //   })
    // );

    if (aadharNumber.length !== 12) {
      setLoadingCTA(false);
      setToast({
        open: true,
        isSuccess: false,
        msg: "Aadhar Number must be of 12 Digits",
      });
      return;
    }

    setLoadingCTA(true);
    let data = {
      aadhar_number: aadharNumber,
      owner_name: name,
    };
    uploadDocument(data, gotoNextPage);
  };

  return (
    <div className="aadhar-screen">
      <Header />
      <div className="box">
        <ProgressBar value={40} />
        <div className="container">
          <p>Document: AADHAAR Card</p>

          <form onSubmit={(e) => handleSubmit(e)}>
            <h1>Enter your Name</h1>
            <TextField
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              variant="standard"
              placeholder="Jane Cooper"
            />

            <h1>Enter AADHAAR Number</h1>
            <TextField
              required
              value={aadharNumber}
              onChange={(e) => setAadharNumber(e.target.value)}
              fullWidth
              type="number"
              variant="standard"
              placeholder="0000-0000-000"
            />
            <div className="cta-container">
              <PrimaryCTA
                loading={loadingCTA}
                disabled={loadingCTA}
                type="submit"
                text="Next"
              />
            </div>
          </form>
        </div>
      </div>
      <Toast
        data={toast}
        handleClose={() =>
          setToast({
            open: false,
            isSuccess: false,
            msg: "",
          })
        }
      />
    </div>
  );
}
