import React, { useState } from "react";
import Header from "../../components/Header";
import ProgressBar from "../../components/ProgressBar";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import PrimaryCTA from "./../../components/PrimaryCTA";
import { setBusiness } from "../../Reducer/userReducer";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { uploadDocument } from "../../Actions/User";

export default function BusinessDetails() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [type, setType] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [name, setName] = useState("");
  const [loadingCTA, setLoadingCTA] = useState(false);

  const gotoNextPage = () => {
    history.push("/business-details-upload");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingCTA(true);
    // console.log("type", type, panNumber, name);
    // dispatch(
    //   setBusiness({
    //     type,
    //     panNumber,
    //     name,
    //   })
    // );

    let data = {
      pan_card_number: panNumber,
      business_type: type,
      business_legal_name: name,
    };
    uploadDocument(data, gotoNextPage);
  };

  const handleChange = (event) => {
    setType(event.target.value);
  };
  return (
    <div className="business-details-screen">
      <Header />
      <div className="box">
        <ProgressBar value={20} />
        <div className="container">
          <p>Document: Business Detail</p>

          <form onSubmit={(e) => handleSubmit(e)}>
            <FormControl
              className="form"
              variant="standard"
              sx={{ m: 0, width: "100%" }}
            >
              <InputLabel id="demo-simple-select-standard-label">
                Select Business Type
              </InputLabel>
              <Select
                required
                value={type}
                onChange={handleChange}
                disableUnderline
              >
                <MenuItem value={"SOLE_PROPRIETORSHIP"}>
                  Sole Proprietorship
                </MenuItem>

                <MenuItem value={"PARTNERSHIP"}>Partnership</MenuItem>
                <MenuItem value={"LIMITED_LIABILITY_COMPANY"}>
                  Limited Liability Company
                </MenuItem>
              </Select>

              <h1>Enter PAN Number </h1>
              <TextField
                value={panNumber}
                onChange={(e) => setPanNumber(e.target.value)}
                variant="standard"
                placeholder="AAAA00034"
                required
              />

              <h1>Enter Business Name </h1>
              <TextField
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                variant="standard"
                placeholder="Albert Flores"
              />
              <div className="cta-container">
                <PrimaryCTA
                  loading={loadingCTA}
                  disabled={loadingCTA}
                  type="submit"
                  text="Next"
                />
              </div>
            </FormControl>
          </form>
        </div>
      </div>
    </div>
  );
}
