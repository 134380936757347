import React, { useState } from "react";
import Header from "../../components/Header";
import ProgressBar from "../../components/ProgressBar";
import TextField from "@mui/material/TextField";
import PrimaryCTA from "./../../components/PrimaryCTA";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setGst } from "../../Reducer/userReducer";
import { uploadDocument } from "../../Actions/User";

export default function GST() {
  const dispatch = useDispatch();
  const [gst, setGstNumber] = useState("");
  const history = useHistory();

  const gotoNextPage = () => {
    history.push("/thank-you");
  };

  const handleClick = () => {
    dispatch(setGst(gst));
    let data = {
      gst_number: gst,
    };
    uploadDocument(data, gotoNextPage);
  };
  return (
    <div className="gst-screen">
      <Header />
      <div className="box">
        <ProgressBar value={90} />
        <div className="container">
          <p>Document: GST</p>

          <h1>Enter GST Number</h1>
          <TextField
            value={gst}
            onChange={(e) => setGstNumber(e.target?.value)}
            fullWidth
            variant="standard"
            placeholder="AAAA00034"
          />
          <div className="cta-container">
            <PrimaryCTA onClick={handleClick} text="Next" />
          </div>
          <h2 onClick={() => history.push("/thank-you")}>
            Skip This Docunment
          </h2>
        </div>
      </div>
    </div>
  );
}
