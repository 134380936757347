import React from "react";
import { Button } from "@mui/material";
import { CircularProgress } from "@mui/material";

export default function PrimaryCTA({ text, loading, ...rest }) {
  return (
    <div className="primary-cta">
      <Button variant="contained" {...rest}>
        {loading ? <CircularProgress /> : text}
      </Button>
    </div>
  );
}
