import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  selfieImg: "",
  business: {
    type: "",
    panNumber: "",
    name: "",
  },
  panCardImg: "",
  aadhar: {
    aadharNumber: "",
    name: "",
  },
  aadharImg1: "",
  aadharImg2: "",
  fssaiNumber: "",
  fssaiImg: "",
  gst: "",
};

const userReducer = createSlice({
  name: "user",
  initialState: defaultState,
  reducers: {
    setSelfie: (state, action) => {
      state.selfieImg = action.payload;
    },
    setBusiness: (state, action) => {
      state.business = action.payload;
    },
    setPanImg: (state, action) => {
      state.panCardImg = action.payload;
    },
    setAadhar: (state, action) => {
      state.aadhar = action.payload;
    },
    setAadharImgFront: (state, action) => {
      state.aadharImg1 = action.payload;
    },
    setAadharImgBack: (state, action) => {
      state.aadharImg2 = action.payload;
    },
    setFssaiNumber: (state, action) => {
      state.fssaiNumber = action.payload;
    },
    setFssaiImg: (state, action) => {
      state.fssaiImg = action.payload;
    },
    setGst: (state, action) => {
      state.gst = action.payload;
    },
    resetUserReducer: (state) => defaultState,
  },
});

export const {
  setSelfie,
  setBusiness,
  setPanImg,
  setAadhar,
  setAadharImgFront,
  setAadharImgBack,
  setFssaiNumber,
  setFssaiImg,
  setGst,
  resetUserReducer,
} = userReducer.actions;

export default userReducer.reducer;
