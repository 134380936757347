import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import PrimaryCTA from "../../components/PrimaryCTA";
import { useHistory } from "react-router-dom";
import { getDocument } from "../../Actions/User";
import LoadingScreen from "./LoadingScreen";
import { getTokenfromUrl } from "../../Config/Config";

export default function Home() {
  const [checked, setChecked] = useState(false);
  const [ctaLoading, setCtaLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    let token = getTokenfromUrl();

    if (token) {
      localStorage.setItem("token", token);
    }

    //old code

    // if (localStorage?.getItem("token")) {
    // } else {
    //   localStorage.setItem("token", token);
    // }
    getUserInfo();
  }, []);

  const getUserInfo = () => {
    setCtaLoading(true);
    getDocument(history, setCtaLoading);
  };

  if (ctaLoading) return <LoadingScreen />;
  return (
    <div className="home-screen">
      <Header />
      <div className="box">
        <p>
          Get you store verified and start accepting unlimited online
          transactions
        </p>
        <p>Pleasekeep these documents ready for the next steps.</p>
        <p style={{ fontWeight: "bold" }}>
          PAN Card <br /> Aadhaar Card <br /> GST Number <br />
        </p>
        <div className="checkbox-bar">
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onClick={(e) => setChecked(e.target?.checked)}
              />
            }
            label={
              <>
                I accept the{" "}
                <span style={{ color: "#0064C0" }}>terms & conditions </span>{" "}
              </>
            }
          />
        </div>
        <div className="cta-container">
          {checked && (
            <PrimaryCTA
              onClick={() => history.push("/selfie")}
              text="Continue"
              disabled={ctaLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
}
