import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "../screens/Home/Home";
import SelfieUplaod from "../screens/Selfie/Selfie";

import BusinessDetails from "../screens/BusinessDetails/BusinessDetails";
import BusinessDetailsUpload from "../screens/BusinessDetails/BusinessDetailsUpload";
import Aadhar from "../screens/Aadhar/Aadhar";
import AadharUpload from "../screens/Aadhar/AadharUpload";
import AadharUploadBank from "../screens/Aadhar/AadharUploadBack";
import GST from "../screens/GST/GST";
import Fssai from "../screens/Fssai/Fssai";
import FssaiUpload from "../screens/Fssai/FssaiUpload";
import ThankYou from "../screens/ThankYou/ThakYou";
import Unauthorized from "../screens/Unauthorized/Unauthorized";
import Bank from "../screens/Bank/Bank";

export default function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/selfie" component={SelfieUplaod} />

        <Route exact path="/business-details" component={BusinessDetails} />
        <Route
          exact
          path="/business-details-upload"
          component={BusinessDetailsUpload}
        />

        <Route exact path="/aadhar" component={Aadhar} />
        <Route exact path="/aadhar-upload" component={AadharUpload} />
        <Route exact path="/aadhar-upload-back" component={AadharUploadBank} />

        {/* <Route exact path="/fssai" component={Fssai} />
        <Route exact path="/fssai-upload" component={FssaiUpload} /> */}

        <Route exact path="/bank" component={Bank} />

        <Route exact path="/gst" component={GST} />
        <Route exact path="/thank-you" component={ThankYou} />

        <Route exact path="/404" component={Unauthorized} />
      </Switch>
    </BrowserRouter>
  );
}
