import React, { useState } from "react";
import Header from "../../components/Header";
import PrimaryCTA from "../../components/PrimaryCTA";
import ProgressBar from "../../components/ProgressBar";
import UploadPhoto from "../../components/UploadPhoto";
import { useHistory } from "react-router-dom";
import { uplaodImage } from "../../Actions/User";
import { useDispatch } from "react-redux";
import { setSelfie } from "../../Reducer/userReducer";
import { uploadDocument } from "../../Actions/User";

export default function SelfieUplaod() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [response, setResponse] = useState("");
  const [step, setStep] = useState(0);
  const [loadingCTA, setLoadingCTA] = useState(false);

  const gotoNextPage = () => {
    history.push("/business-details");
  };
  const handleSubmit = () => {
    setLoadingCTA(true);
    // dispatch(setSelfie(response));
    const data = {
      user_selfie: response,
    };
    uploadDocument(data, gotoNextPage);
  };

  const handleImageUpload = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      setStep(1);
      const formData = new FormData();
      formData.append("file", files[0]);
      uplaodImage(formData, setResponse, setStep);
    }
  };

  return (
    <div className="selfie-screen-upload">
      <Header />
      <div className="box">
        <ProgressBar value={10} />
        <div className="container">
          <p>Please click selfie of you holding your Aadhaar/DL/Passport:</p>

          <UploadPhoto
            step={step}
            handleImageUpload={handleImageUpload}
            displayImage={response}
          />

          <div className="cta-container">
            {step === 2 && (
              <PrimaryCTA
                text="Next"
                loading={loadingCTA}
                disabled={loadingCTA}
                onClick={() => handleSubmit()}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
